import c3api from "@/c3api";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default function useDamageReports() {
  const damageLoading = ref(false);

  const damage = ref([]);
  const damagePhotos = ref([]);
  const damageTypes = ref([]);
  const toast = useToast();
  const router = useRouter();


  const workflowStateColor = (state) => {
    switch (state) {
      case "new":
        return "secondary";
      case "processing":
        return "blue";
      case "in_review":
        return "#7393B3";
      case "reviewing":
        return "indigo";
      case "rejected":
        return "orange";
      case "approved":
        return "success";
      case "destroyed":
        return "danger";
      default:
        return "primary";
    }
  }

  const damageTypeColor = (type) => {
   switch (type) {
    case "before_arrival":
      return "primary";
    case "handling_damage":
      return "error";
    case "storage_damage":
      return "warning";
    default:
      return "primary";
    }
  }
  
  const startProcessingDR = async (id) => {
    try {
      damageLoading.value = true;
      const response = await c3api.post(`/damage_reports/${id}/start_processing`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const processDamageReport = async (id, note) => {
    try {
      damageLoading.value = true;
      const params = { note };
      const response = await c3api.post(`/damage_reports/${id}/process`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const approveDamageReport = async (id, note) => {
    try {
      damageLoading.value = true;
      const params = { note };
      const response = await c3api.post(`/damage_reports/${id}/approve`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const rejectDamageReport = async (id, note) => {
    try {
      damageLoading.value = true;
      const params = { note };
      const response = await c3api.post(`/damage_reports/${id}/reject`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const startDamageReview = async (id) => {
    try {
      damageLoading.value = true;
      const response = await c3api.post(`/damage_reports/${id}/start_review`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const fetchDamage = async (id) => {
    try {
      damageLoading.value = true;
      const response = await c3api.get(`/damage_reports/${id}`);
      console.log(response.data.data);
      return (damage.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const fetchDamagePhotos = async (id) => { 
    try {
      damageLoading.value = true;
      const response = await c3api.get(`/damage_reports/${id}/photos`);
      return (damagePhotos.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const fetchDamageNotes = async (id) => {
    try {
      damageLoading.value = true;
      const response = await c3api.get(`/damage_reports/${id}/notes`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  }
  
  const fetchDamageTypes = async () => {
    try {
      damageLoading.value = true;
      const response = await c3api.get(`/damage_reports/damage_types`);
      return (damageTypes.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  };

  const uploadDamagePhoto = async (id, formData) => {
    try {
      damageLoading.value = true;
      const response = await c3api.post(`/damage_reports/${id}/photos`, formData, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      });
      toast.success("Photo uploaded successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  }

  const addDamageReport = async (data) => {
    try {
        damageLoading.value++;
        const response = await c3api.post("/damage_reports", data);
        toast.success("Damage report added successfully.");
        router.push('/damage-reports');
    } catch (error) {
        toast.error(error.response.data.data.join(". "));
    } finally {
      damageLoading.value--;
    }
  }

  const addDamageNote = async (id, params) => {
    try {
      damageLoading.value = true;
      const response = await c3api.post(`/damage_reports/${id}/notes`, params);
      toast.success("Note added successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  }

  const deleteDamageNote = async (id, noteId) => {
    try {
      damageLoading.value = true;
      const response = await c3api.delete(`/damage_reports/${id}/notes/${noteId}`);
      toast.success("Note deleted successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  }

  const fetchAvailableBins = async (id) => {
    try {
      damageLoading.value = true;
      const response = await c3api.get(`/lps/${id}/available_damage_bins`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      damageLoading.value = false;
    }
  }

  return {
    startProcessingDR,
    processDamageReport,
    approveDamageReport,
    rejectDamageReport,
    startDamageReview,
    addDamageReport,
    addDamageNote,
    deleteDamageNote,
    fetchDamage,
    damage,
    fetchDamagePhotos,
    fetchDamageNotes,
    damagePhotos,
    uploadDamagePhoto,
    damageTypes,
    workflowStateColor,
    damageTypeColor,
    fetchDamageTypes,
    damageLoading,
    fetchAvailableBins,
  };
}
